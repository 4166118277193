import React from 'react'
import { linkResolver } from "../../utils/linkResolver"
import { Link } from "gatsby"


export default ({ lessons }) => {

    const recipes = lessons.filter(linked => linked.lesson.lesson_type === 'Rezepte')
    const techniques = lessons.filter(linked => linked.lesson.lesson_type === 'Techniken')
    const tools = lessons.filter(linked => linked.lesson.lesson_type === 'Utensilien')
    const ingredients = lessons.filter(linked => linked.lesson.lesson_type === 'Zutaten')

    return (

        <div className="grid grid-cols-2 row-gap-4 md:row-gap-8 md:grid-cols-2">
            { recipes && 
            <div className="">
                <h3 className="font-caps text-sm uppercase tracking-widest">Rezepte</h3>
                <div className="">
                {
                    recipes.map(linked => {
                    return (
                        <div key={linked.lesson._meta.id}>
                            <Link to={linkResolver(linked.lesson._meta)}>
                                {linked.lesson.title}
                            </Link>
                        </div>
                    )
                    })
                }
                </div>
            </div>
            }
            { techniques &&
            <div className="">
                <h3 className="font-caps text-sm uppercase tracking-widest">Techniken</h3>
                <div className="">
                {
                    techniques.map(linked => {
                    return (
                        <div key={linked.lesson._meta.id}>
                            <Link to={linkResolver(linked.lesson._meta)}>
                                {linked.lesson.title}
                            </Link>
                        </div>
                    )
                    })
                }
                </div>
            </div>
            }
            { tools &&
            <div className="">
                <h3 className="font-caps text-sm uppercase tracking-widest">Utensilien</h3>
                <div className="">
                {
                    tools.map(linked => {
                    return (
                        <div key={linked.lesson._meta.id}>
                            <Link to={linkResolver(linked.lesson._meta)}>
                                {linked.lesson.title}
                            </Link>
                        </div>
                    )
                    })
                }
                </div>
            </div>
            }
            { ingredients &&
            <div className="">
                <h3 className="font-caps text-sm uppercase tracking-widest">Zutaten</h3>
                <div className="">
                {
                    ingredients.map(linked => {
                    return (
                        <div key={linked.lesson._meta.id}>
                            <Link to={linkResolver(linked.lesson._meta)}>
                                {linked.lesson.title}
                            </Link>
                        </div>
                    )
                    })
                }
                </div>
            </div>
            }
        </div>

    )

}