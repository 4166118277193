import React from 'react'
import { RichText, Date } from 'prismic-reactjs'
import Sorted from "./partials/sorted"
import { linkResolver } from "../utils/linkResolver"
import { Link } from "gatsby"
import Imgix from "../components/imgix"

export default ({ post }) => {

    const date = Date(post.date);
    const formattedDate = Intl.DateTimeFormat('de-DE',{
    year: 'numeric',
    month: 'long',
    day: '2-digit' }).format(date);
    // Output in M d, Y format

return (
<div className="bg-white md:shadow md:border md:border-gray-200 md:rounded-lg md:overflow-hidden md:mx-8">
    <div className="relative bg-white">
        <div className="h-56 sm:h-72 lg:absolute lg:left-0 lg:h-full lg:w-1/2">
            <Imgix image={post.beauty} classes={`w-full h-full object-cover`} />
        </div>
        <div className="relative max-w-screen-xl mx-auto px-6 py-8 sm:py-12 lg:py-16">
            <div className="max-w-2xl mx-auto lg:max-w-none lg:mr-0 lg:ml-auto lg:w-1/2 lg:pl-10">
                <p className="md:mt-16 font-caps uppercase tracking-widest text-canred">{formattedDate}</p>
                <Link to={linkResolver(post.lessons[0].lesson._meta)}>
                    <h2 className="mt-6 text-3xl leading-tight font-display">
                        {post.title}
                    </h2>
                </Link>
                <div className="mt-8 richtext">
                    { RichText.render(post.description) }
                </div>
                <div className="mt-12 inline-flex rounded-md shadow-sm">
                    <Link to={linkResolver(post.lessons[0].lesson._meta)} className="inline-flex items-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-canblue hover:bg-canred focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                        Los geht's
                    </Link>
                </div>
                <div className="pb-8">
                    <h3 className="mt-8 font-caps tracking-widest uppercase text-sm md:mt-12">Im Rezept enthalten:</h3>
                    <div className="border-t border-gray-100 mt-4 pt-4">
                        {
                            post.lessons &&
                            <Sorted lessons={post.lessons} />
                                    
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>
    
)

}