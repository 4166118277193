import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Post from "../components/post"

export const query = graphql`
  {
    prismic {
      allPosts {
        edges {
          node {
            title
            description
            date
            beauty
            _meta {
              id
              uid
              type
            }
            lessons {
              lesson {
                ... on PRISMIC_Lesson {
                  title
                  lesson_type
                  _meta {
                    id
                    uid
                    type
                    lang
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ({ data }) => {
  const posts = data.prismic.allPosts.edges

  const Posts = ({ posts }) => {
    if (!posts) return null
    return (
      <div className="relative pt-20">
        {posts.map(({ node:post }) => {
          return (
            <Post key={post._meta.id} post={post} />
          )
        })}
      </div>
    )
  }

  return (
    <Layout title="Neuste Rezepte">
      <div className="pt-8">
        <div className="px-6 max-w-2xl mx-auto">
          <p className="mt-6 font-caps uppercase tracking-widest text-sm text-gray-400 font-medium md:mt-16">BASIC</p>
          <h1 className="mt-20 font-display text-4xl md:text-6xl">Lieblingsrezepte</h1>
          <p className="mt-8 text-xl max-w-xl leading-relaxed font-display">
            Du lernst die Greatest Hits der Patisserie, zum nachkochen daheim - 
            Schritt für Schritt, von Daniel Budde.
          </p>
          <div className="mt-8">
            <div className="flex items-center ">
              <svg className="ml-2 h-5 w-5 text-canred" fill="currentColor" viewBox="0 0 24 24">
                <path d="M18.59 13H3a1 1 0 0 1 0-2h15.59l-5.3-5.3a1 1 0 1 1 1.42-1.4l7 7a1 1 0 0 1 0 1.4l-7 7a1 1 0 0 1-1.42-1.4l5.3-5.3z"/>
              </svg>
              <p className="ml-2 font-sans text-lg font-bold">Rezepte, die funktionieren</p>
            </div>
            <div className="flex items-center ">
              <svg className="ml-2 h-5 w-5 text-canred" fill="currentColor" viewBox="0 0 24 24">
                <path d="M18.59 13H3a1 1 0 0 1 0-2h15.59l-5.3-5.3a1 1 0 1 1 1.42-1.4l7 7a1 1 0 0 1 0 1.4l-7 7a1 1 0 0 1-1.42-1.4l5.3-5.3z"/>
              </svg>
              <p className="ml-2 font-sans text-lg font-bold">Tips und Videos zu Grundtechniken der Patisserie</p>
            </div>
            <div className="flex items-center ">
              <svg className="ml-2 h-5 w-5 text-canred" fill="currentColor" viewBox="0 0 24 24">
                <path d="M18.59 13H3a1 1 0 0 1 0-2h15.59l-5.3-5.3a1 1 0 1 1 1.42-1.4l7 7a1 1 0 0 1 0 1.4l-7 7a1 1 0 0 1-1.42-1.4l5.3-5.3z"/>
              </svg>
              <p className="ml-2 font-sans text-lg font-bold">Inklusive: Hintergrundwissen für Zutaten und Tools</p>
            </div>
          </div>
          <p className="mt-8 text-xl max-w-xl leading-relaxed font-display">
            Ab sofort posten wir hier neue Rezepte, Techniken und mehr
            und freuen uns auf euer Feedback (#nailedit)
          </p>
          <div className="mt-12 pb-12 border-t">
              <h2 className="mt-20 text-2xl leading-9 font-display tracking-tight text-gray-900 sm:text-3xl sm:leading-10">
                Super, ich muss <span className="border-b border-canred">sofort</span> wissen,
                <br className="hidden sm:inline" />
                <span className="text-canblue"> wenn ein neues Rezept online ist</span>
              </h2>
              
              <form 
                className="mt-8"
                action="https://app.convertkit.com/forms/1299827/subscriptions" 
                method="POST" 
                data-sv-form="1299827" 
                data-uid="427c80412d"
                data-version="5"
                data-options='{"settings":{"after_subscribe":{"success_message":"Danke, wir geben dir bescheid."}}}'
              >
                <ul data-element="errors"></ul>
                <div className="sm:flex" data-element="fields">

                  <input aria-label="Email adresse" type="email" required  name="email_address" className="appearance-none w-full px-5 py-3 border border-gray-300 text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out sm:max-w-xs" placeholder="Email eintragen" />
                  <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                    <button data-element="submit" className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                      Gib mir bescheid
                    </button>
                  </div>
                </div>
              </form>
          </div>
        </div>
        <div className="bg-white mt-20 pb-20">
          <div className="relative">
            <div className="hidden md:block absolute inset-0">
              <div className="bg-gray-50 h-1/3 sm:h-1/2"></div>
            </div>
            <Posts posts={posts} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

